import dayjs from "dayjs";
import ja from "dayjs/locale/ja";
dayjs.locale(ja);

// ****************************************
// 機能：遅延処理（デバッグ用：通信のラグを疑似的に再現します）
// 備考：！！！本番環境時は必ず0にしてください！！！
// ****************************************
export function sleep(time) {
  return new Promise(function (resolve) {
    window.setTimeout(resolve, time);
  });
}

// **************************************************
// 目的：日付形式変換
// 引数：文字列
// **************************************************
export function formatDate(value, format) {
  // 入力がない場合、空白を返却
  if (value == "") {
    return "[未入力エラー]";
  }
  // 形式が指定されていない場合、空白を返却
  if (format == "") {
    return "[形式エラー]";
  }
  // 日付が存在しない場合、空白を返却
  if (!dayjs(value).isValid()) {
    return "[妥当性エラー]";
  }
  return dayjs(value).format(format);
}

// **************************************************
// 目的：数値整形（カンマあり）
// 引数：文字列
// **************************************************
export function addComma(value) {
  // 全角→半角に変換
  value = zenkakuToHankaku(value);
  // 文字列→数値に変換
  value = Number(value);
  if (isNaN(value)) {
    // 数値ではない場合、0を返却
    return 0;
  }
  // カンマ区切りに変換
  value = value.toLocaleString();

  return value;
}

// **************************************************
// 目的：数値整形（カンマなし）
// 引数：文字列
// **************************************************
export function delComma(value) {
  // カンマ削除
  value = value.replace(/,/g, "");
  // 全角→半角に変換
  value = zenkakuToHankaku(value);
  // 文字列→数値に変換
  value = Number(value);
  if (isNaN(value)) {
    // 数値ではない場合、0を返却
    return 0;
  }

  return value;
}

// **************************************************
// 目的：文字列変換（全角数字→半角数字）
// 引数：文字列
// **************************************************
export function zenkakuToHankaku(str) {
  // 変換パターン定義
  const zenkakuNumbers = "０１２３４５６７８９";
  const hankakuNumbers = "0123456789";

  const regex = new RegExp(`[${zenkakuNumbers}]`, "g");
  return str.toString().replace(regex, (match) => {
    const index = zenkakuNumbers.indexOf(match);
    return index !== -1 ? hankakuNumbers[index] : match;
  });
}

// **************************************************
// 目的：日付範囲チェック
// 引数：文字列
// **************************************************
export function checkDateRange(value_st, value_ed) {
  // 入力がない場合、チェックしない
  if (value_st != "" || value_ed != "") {
    // 日付が不正の場合、チェックしない
    if (!dayjs(value_st).isValid() || !dayjs(value_ed).isValid()) {
      return false;
    }
    // 日付範囲チェック
    if (new Date(value_st) > new Date(value_ed)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック
// 引数：文字列
// **************************************************
export function checkNum(value) {
  // チェックパターン定義
  const pattern = /^\d+$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック（カンマあり）
// 引数：文字列
// **************************************************
export function checkNumComma(value) {
  // チェックパターン定義
  const pattern = /^[\d,/]+$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック（スラッシュあり）
// 引数：文字列
// **************************************************
export function checkNumSlash(value) {
  // チェックパターン定義
  const pattern = /^[\d/]+$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：数値チェック（ハイフンあり）
// 引数：文字列
// **************************************************
export function checkNumHyphen(value) {
  // チェックパターン定義
  const pattern = /^\d+(-\d*)*$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 数値チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：カナチェック（カナ、英数）
// 引数：文字列
// **************************************************
export function checkKana(value) {
  // チェックパターン定義
  const pattern1 = /^[\p{Script=Katakana}\p{Alphabetic}\p{Decimal_Number}]+$/u; // カタカナ、アルファベット、数字
  const pattern2 = /^[^\p{Script=Han}]+$/u; // 漢字以外
  // 入力がない場合、チェックしない
  if (value != "") {
    // カナチェック
    if (!pattern1.test(value) || !pattern2.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：文字列チェック（半角英数）
// 引数：文字列
// **************************************************
export function checkHanEisu(value) {
  // チェックパターン定義
  const pattern = /^[a-zA-Z0-9]*$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 文字列チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}

// **************************************************
// 目的：文字列チェック（半角英数記号）
// 引数：文字列
// **************************************************
export function checkHanKigo(value) {
  // チェックパターン定義
  const pattern = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/;
  // 入力がない場合、チェックしない
  if (value != "") {
    // 文字列チェック
    if (!pattern.test(value)) {
      return false;
    }
  }
  return true;
}
