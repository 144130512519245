<!--------------------------------------------------
  システム名：弁当注文ビフォサック（タブレット版）
  プログラム名：SystemBar.vue
  モジュール名：システムバー
  備考：
-------------------------------------------------->
<template>
  <v-system-bar app>
    <!-- バージョン情報 -->
    <span>
      <strong>Ver. {{ $version }}</strong>
    </span>
    <!-- 接続状態 -->
    <span class="mx-2">
      <!-- APIサーバー -->
      <v-tooltip bottom :color="$store.state.isServer ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="$store.state.isServer ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">language</v-icon>
        </template>
        <span v-if="$store.state.isServer">APIサーバー：接続済</span>
        <span v-if="!$store.state.isServer">APIサーバー：未接続</span>
      </v-tooltip>
      <!-- データベース -->
      <v-tooltip bottom :color="$store.state.isDatabase ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="$store.state.isDatabase ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">storage</v-icon>
        </template>
        <span v-if="$store.state.isDatabase">データベース：接続済</span>
        <span v-if="!$store.state.isDatabase">データベース：未接続</span>
      </v-tooltip>
      <!-- カードリーダー -->
      <v-tooltip bottom :color="$store.state.isCardReader ? 'success' : 'grey'">
        <template v-slot:activator="{ on, attrs }">
          <v-icon class="mx-1" :color="$store.state.isCardReader ? 'success' : 'grey'" style="font-size: 1.4rem" v-bind="attrs" v-on="on">badge</v-icon>
        </template>
        <span v-if="$store.state.isCardReader">カードリーダー：接続済</span>
        <span v-if="!$store.state.isCardReader">カードリーダー：未接続</span>
      </v-tooltip>
    </span>
    <v-spacer></v-spacer>
    <!-- 現在日時 -->
    <span>
      <strong>{{ nowTime }}</strong>
    </span>
  </v-system-bar>
</template>

<script>
import * as api from "@/api/client";
import dayjs from "dayjs";
import { reader_check } from "@/utils/auth";

export default {
  name: "SystemBar",
  data: () => ({
    nowTime: "",
    // statusServer: false,
    // statusDatabase: false,
    // statusCardReader: false,
  }),
  methods: {
    // 接続チェック
    checkStatus() {
      this.checkServer();
      this.checkDatabase();
      this.checkCardReader();
      // setTimeout(() => {
      //   this.checkStatus();
      // }, 30000);
    },

    // サーバー接続チェック
    async checkServer() {
      try {
        const responce = await api.getCheckServer();
        // this.statusServer = responce.data["result"];
        this.$store.state.isServer = responce.data["result"];
      } catch {
        // this.statusServer = false;
        this.$store.state.isServer = false;
      }
    },

    // データベース接続チェック
    async checkDatabase() {
      try {
        const responce = await api.getCheckDB();
        // this.statusDatabase = responce.data["result"];
        this.$store.state.isDatabase = responce.data["result"];
      } catch {
        // this.statusDatabase = false;
        this.$store.state.isDatabase = false;
      }
    },

    // カードリーダー接続チェック
    async checkCardReader() {
      try {
        const responce = await reader_check();
        // this.statusCardReader = responce;
        this.$store.state.isCardReader = responce;
      } catch {
        // this.statusCardReader = false;
        this.$store.state.isCardReader = false;
      }
    },

    timeFormate(timeStamp) {
      let nowYear = dayjs(timeStamp).format("YYYY");
      let nowMonth = dayjs(timeStamp).format("MM");
      let nowDay = dayjs(timeStamp).format("DD");
      let nowHour = dayjs(timeStamp).format("HH");
      let nowMin = dayjs(timeStamp).format("mm");
      let nowSec = dayjs(timeStamp).format("ss");

      // システムバー時刻
      this.nowTime = nowYear + "/" + nowMonth + "/" + nowDay + " " + nowHour + ":" + nowMin + ":" + nowSec;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setTimeout(() => {
        this.nowTimes();
      }, 500);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkStatus();
      this.nowTimes();
    });
  },
};
</script>
