<!--------------------------------------------------
  システム名：弁当注文ビフォサック（タブレット版）
  プログラム名：ConfirmDialog.vue
  モジュール名：確認ダイアログ
  備考：
-------------------------------------------------->
<template>
  <v-dialog v-model="dialog" width="600px" @keydown.esc="onClickCansel" persistent no-click-animation>
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="py-2 text--primary" style="font-size: 1.2rem; white-space: pre-wrap">{{ message }}</v-card-text>
      <v-card-actions class="mt-4">
        <v-container fluid>
          <v-row>
            <v-col cols="6" class="pa-0 pr-1">
              <v-btn @click.native="onClickAgree()" color="primary" dark large block depressed>{{ btnYes }}</v-btn>
            </v-col>
            <v-col cols="6" class="pa-0 pl-1">
              <v-btn @click.native="onClickCansel()" color="blue-grey" dark large block depressed>{{ btnNo }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: () => ({
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: "確認",
    btnYes: "はい",
    btnNo: "いいえ",
  }),
  methods: {
    openDialog(option) {
      this.title = option.title != null ? option.title : "確認";
      this.message = option.message != null ? option.message : "確認メッセージ";
      this.btnYes = option.btnYes != null ? option.btnYes : "はい";
      this.btnNo = option.btnNo != null ? option.btnNo : "いいえ";
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    onClickAgree() {
      this.resolve(true);
      this.dialog = false;
    },
    onClickCansel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
