import { getRequest, postRequest } from "./endpoint";

// アクセス元識別文字列
const sAccess = "TAB";

// ****************************************
// 接続チェック
// ****************************************
export async function getCheckServer() {
  return await getRequest("/auth/server");
}
export async function getCheckDB() {
  return await getRequest("/auth/db");
}

// ****************************************
// ログイン
// ****************************************
export async function postAuthLogin(KigyoCd, Login, Password) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("Login", Login);
  params.append("Password", Password);
  return await postRequest("/auth/login", params);
}
export async function postAuthCard(KigyoCd, IcIdm) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("IcIdm", IcIdm);
  return await postRequest("/auth/card", params);
}
export async function postAuthQr(KigyoCd, UserCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  return await postRequest("/auth/qr", params);
}
export async function postAuthPass(KigyoCd, UserCd, NewPass) {
  const params = new URLSearchParams();
  params.append("Password", NewPass);
  params.append("sUser", UserCd);
  params.append("sAccess", sAccess);
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  return await postRequest("/auth/pass", params);
}

// ****************************************
// 弁当マスタ
// ****************************************
export async function getBentoList(KigyoCd, JigyoNo, GyoshaNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
    GyoshaNo: GyoshaNo,
  };
  return await getRequest("/m_bento_get/bento_list", params);
}
export async function getBentoListJigyo(KigyoCd, JigyoNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
  };
  return await getRequest("/m_bento_get/bento_list_jigyo", params);
}

// ****************************************
// 弁当業者マスタ
// ****************************************
export async function getGyoshaList(KigyoCd, JigyoNo) {
  const params = {
    KigyoCd: KigyoCd,
    JigyoNo: JigyoNo,
  };
  return await getRequest("/m_gyosha_get/gyosha_list", params);
}

// ****************************************
// 事業所マスタ
// ****************************************
export async function getJigyoList(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_jigyo_get/jigyosyo_list", params);
}

// ****************************************
// 企業マスタ
// ****************************************
export async function getKigyoInfo(KigyoCd) {
  const params = {
    KigyoCd: KigyoCd,
  };
  return await getRequest("/m_kigyo_get/info", params);
}

// ****************************************
// 年間予定テーブル
// ****************************************
export async function getCalendarList(KigyoCd, Year) {
  const params = {
    KigyoCd: KigyoCd,
    Year: Year,
  };
  return await getRequest("/t_calendar_get/list", params);
}

// ****************************************
// 注文予約テーブル
// ****************************************
export async function getChuYoyakuListMonthly(KigyoCd, UserCd, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_monthly", params);
}
export async function getChuYoyakuListDaily(KigyoCd, UserCd, Chumonbi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Chumonbi: Chumonbi,
  };
  return await getRequest("/t_chu_yoyaku_get/yoyaku_list_daily", params);
}
export async function getChuYoyakuMonthlyPrice(KigyoCd, UserCd, Simebi) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
    Simebi: Simebi,
  };
  return await getRequest("/t_chu_yoyaku_get/monthly_price", params);
}
export async function postChuYoyakuInsert(KigyoCd, JigyoNo, UserCd, Chu_st, Chu_ed, Chu_week, GyoshaNo, BentoNo, Kingaku, Kosuu, Goukei, sUser) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("JigyoNo", JigyoNo);
  params.append("UserCd", UserCd);
  params.append("Chu_st", Chu_st);
  params.append("Chu_ed", Chu_ed);
  params.append("Chu_week", Chu_week);
  params.append("GyoshaNo", GyoshaNo);
  params.append("BentoNo", BentoNo);
  params.append("Kingaku", Kingaku);
  params.append("Kosuu", Kosuu);
  params.append("Goukei", Goukei);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/t_chu_yoyaku_post/insert", params);
}
export async function postChuYoyakuDelete(sUser, tKigyoCd, tJigyoNo, tUserCd, tChumonbi, tSeqNo) {
  const params = new URLSearchParams();
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tJigyoNo", tJigyoNo);
  params.append("tUserCd", tUserCd);
  params.append("tChumonbi", tChumonbi);
  params.append("tSeqNo", tSeqNo);
  return await postRequest("/t_chu_yoyaku_post/delete", params);
}
export async function postChuYoyakuReceipt(sUser, tKigyoCd, tJigyoNo, tUserCd, tChumonbi) {
  const params = new URLSearchParams();
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  params.append("tKigyoCd", tKigyoCd);
  params.append("tJigyoNo", tJigyoNo);
  params.append("tUserCd", tUserCd);
  params.append("tChumonbi", tChumonbi);
  return await postRequest("/t_chu_yoyaku_post/receipt", params);
}

// ****************************************
// ログイン履歴テーブル
// ****************************************
export async function postLoginInsert(KigyoCd, UserCd) {
  const params = new URLSearchParams();
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  params.append("sAccess", sAccess);
  return await postRequest("/t_login_post/insert", params);
}

// ****************************************
// お知らせテーブル
// ****************************************
export async function getNoticeList(KigyoCd, UserCd) {
  const params = {
    KigyoCd: KigyoCd,
    UserCd: UserCd,
  };
  return await getRequest("/t_notice_get/list", params);
}
export async function postNoticeRead(NoticeKigyoCd, NoticeNo, KigyoCd, UserCd, sUser) {
  const params = new URLSearchParams();
  params.append("NoticeKigyoCd", NoticeKigyoCd);
  params.append("NoticeNo", NoticeNo);
  params.append("KigyoCd", KigyoCd);
  params.append("UserCd", UserCd);
  params.append("sUser", sUser);
  params.append("sAccess", sAccess);
  return await postRequest("/t_notice_post/read", params);
}
