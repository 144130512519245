// import { NFCPortLib, Configuration, DetectionOption } from "https://cdn.felica-support.sony.biz/webclient/trial/NFCPortLib.js";
import { NFCPortLib, Configuration, DetectionOption } from "./NFCPortLib";

// カードリーダーチェック
export async function reader_check() {
  let lib = null;

  try {
    /* create NFCPortLib object */
    lib = new NFCPortLib();

    /* init() */
    let config = new Configuration(500 /* ackTimeout */, 500 /* receiveTimeout */, true /* autoBaudRate*/, true /* autoDeviceSelect */);
    await lib.init(config);

    /* open() */
    await lib.open();

    /* close() */
    await lib.close();
    lib = null;

    return true;
  } catch (error) {
    if (lib != null) {
      await lib.close();
      lib = null;
    }

    return false;
  } finally {
    if (lib != null) {
      await lib.close();
      lib = null;
    }
  }

  // const filters = [{ vendorId: "054C", productId: "06C3" }];
  // try {
  //   // デバイスをリクエスト
  //   const device = await navigator.usb.requestDevice({ filters });
  //   console.log("デバイスが接続されました:", device);
  //   return true;
  // } catch (error) {
  //   console.error("デバイスにアクセスできません:", error);
  //   return false;
  // }
}

export async function felica_card() {
  // console.log("[Reading a FeliCa Card] Begin");

  let lib = null;
  let idm = "";

  try {
    /* create NFCPortLib object */
    lib = new NFCPortLib();

    /* init() */
    let config = new Configuration(500 /* ackTimeout */, 500 /* receiveTimeout */, true /* autoBaudRate*/, true /* autoDeviceSelect */);
    await lib.init(config);

    /* open() */
    await lib.open();
    // console.log("deviceName : " + lib.deviceName);

    /* detectCard(FeliCa Card) */
    let detectOption = new DetectionOption(new Uint8Array([0xff, 0xff]), 0, true, false, null);
    let card = await lib.detectCard("iso18092", detectOption).then(
      (ret) => {
        // console.log("Card is detected");
        // if (ret.systemCode == null) {
        //   console.log("IDm : " + _array_tohexs(ret.idm) + "\nPMm : " + _array_tohexs(ret.pmm) + "\ntargetCardBaudRate : " + lib.targetCardBaudRate + "kbps");
        // } else {
        //   console.log("IDm : " + _array_tohexs(ret.idm) + "\nPMm : " + _array_tohexs(ret.pmm) + "\nSystemCode : " + _array_tohexs(ret.systemCode) + "\ntargetCardBaudRate : " + lib.targetCardBaudRate + "kbps");
        // }
        console.log("IDm : " + _array_tohexs(ret.idm));
        return ret;
      },
      (error) => {
        // console.log("Card is not detected");
        throw error;
      }
    );
    idm = _array_tohexs(card.idm);

    /* close() */
    await lib.close();
    lib = null;

    console.log("Success");
  } catch (error) {
    // console.log("Error errorType : " + error.errorType);
    // console.log("      message : " + error.message);

    if (lib != null) {
      await lib.close();
      lib = null;
    }

    // throw error;
    // console.log(error.message);
  }

  // console.log("[Reading a FeliCa Card] End");
  return idm;
}

function _def_val(param, def) {
  return param === undefined ? def : param;
}

function _array_slice(array, offset, length) {
  let result;

  length = _def_val(length, array.length - offset);
  result = [];
  _array_copy(result, 0, array, offset, length);

  return result;
}

function _bytes2hexs(bytes, sep) {
  let str;

  sep = _def_val(sep, " ");

  return bytes
    .map(function (byte) {
      str = byte.toString(16);
      return byte < 0x10 ? "0" + str : str;
    })
    .join(sep)
    .toUpperCase();
}

function _array_tohexs(array, offset, length) {
  let temp;

  offset = _def_val(offset, 0);
  length = _def_val(length, array.length - offset);

  temp = _array_slice(array, offset, length);
  return _bytes2hexs(temp, "");
}

function _array_copy(dest, dest_offset, src, src_offset, length) {
  let idx;

  src_offset = _def_val(src_offset, 0);
  length = _def_val(length, src.length);

  for (idx = 0; idx < length; idx++) {
    dest[dest_offset + idx] = src[src_offset + idx];
  }

  return dest;
}
