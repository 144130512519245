<!--------------------------------------------------
  システム名：弁当注文ビフォサック（タブレット版）
  プログラム名：App.vue
  モジュール名：ルートコンポーネント
  備考：
-------------------------------------------------->
<template>
  <v-app>
    <!-- システムバー -->
    <SystemBar />
    <!-- 確認ダイアログ -->
    <ConfirmDialog ref="confirm" />
    <!-- スナックバー -->
    <SnackBar ref="snackbar" />
    <!-- 画面コンポーネント -->
    <router-view />
  </v-app>
</template>

<script>
import SystemBar from "./components/SystemBar";
import ConfirmDialog from "./components/ConfirmDialog";
import SnackBar from "./components/SnackBar";

export default {
  components: {
    SystemBar,
    SnackBar,
    ConfirmDialog,
  },
  methods: {
    // **************************************************
    // ログアウト
    // **************************************************
    logout() {
      if (this.$route.path != "/login") {
        this.$store.commit("logout");
        this.$router.push("/login");
        this.$refs.snackbar.showMessage("ログアウトしました。", "info");
      }
    },
  },
  mounted() {
    this.$root.logout = this.logout;
    this.$root.confirm = this.$refs.confirm;
    this.$root.snackbar = this.$refs.snackbar;
  },
};
</script>
