import Vue from "vue";
import Router from "vue-router";
import Store from "@/store/index.js";

// レイアウト
const LoginLayout = () => import("@/layouts/LoginLayout.vue");
const MainLayout = () => import("@/layouts/MainLayout.vue");

// ページ
const TabletAuth = () => import("@/views/TabletAuth.vue"); // 認証画面
const TabletLogin = () => import("@/views/TabletLogin.vue"); // ログイン画面
const TabletMain = () => import("@/views/TabletMain.vue"); // メイン画面
const TabletOrder = () => import("@/views/TabletOrder.vue"); // 予約画面
const TabletRecept = () => import("@/views/TabletRecept.vue"); // 受取画面
const NotFound = () => import("@/views/NotFound.vue");

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: LoginLayout,
      children: [
        {
          path: "",
          component: TabletAuth,
          name: "TabletAuth",
        },
        {
          path: "login",
          component: TabletLogin,
          name: "TabletLogin",
          meta: {
            requiresAuth: false,
          },
        },
        {
          path: "main",
          component: TabletMain,
          name: "TabletMain",
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "/",
      component: MainLayout,
      children: [
        {
          path: "order",
          component: TabletOrder,
          name: "TabletOrder",
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "recept",
          component: TabletRecept,
          name: "TabletRecept",
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },

    {
      path: "/:notFound(.*)",
      component: NotFound,
      name: "NotFound",
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Store.state.isLogin) {
      next({
        path: "/",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
